<template>
  <header class="flex items-center justify-between p-6 border-b">
    <div>
      <h1 class="text-3xl font-semibold">Пользователи</h1>
      <div class="mt-1 text-sm font-light text-gray-700">
        {{ users.length }} шт
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex flex-wrap w-full justify-end gap-2 items-center">
        <el-select
          @change="getUsers"
          v-model="filters.type"
          size="large"
          style="width: 240px"
        >
          <el-option :value="null" label="Все"></el-option>
          <el-option :value="1" label="Действующие"></el-option>
          <el-option :value="-1" label="Удаленные"></el-option>
        </el-select>
        <el-input
          type="text"
          v-model="search"
          clearable
          size="large"
          @input="searchUsers"
          placeholder="Введите для поиска"
          style="width: 240px"
        ></el-input>
        <el-select
          @change="getUsers"
          v-model="filters.typeAccount"
          size="large"
          style="width: 240px"
        >
          <el-option label="Все" :value="null"></el-option>
          <el-option label="Верификатор" value="verifier"></el-option>
          <el-option label="Разметчик" value="annotator"></el-option>
          <el-option label="Админ" value="admin"></el-option>
        </el-select>
        <el-button
          type="primary"
          size="large"
          plain
          @click="createUser"
          class="text-sm text-red-600 cursor-pointer w-32 items-center flex"
        >
          + Создать
        </el-button>
      </div>
    </div>
  </header>
  <main class="m-6">
    <el-table
      @row-click="viewUser"
      border
      :data="users"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        type="index"
        label="N"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column prop="first_name" label="Имя"></el-table-column>
      <el-table-column prop="last_name" label="Фамилия"></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column prop="roles" label="Роли"></el-table-column>
      <el-table-column label="Действия">
        <template v-slot="scope">
          <el-button
            circle
            @click.stop.prevent="changePasswordUser(scope.row._id)"
          >
            <svg
              class="ov-icon"
              aria-hidden="true"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#222F3D"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              ></path>
            </svg>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </main>
  <el-dialog v-model="passwordChangeShow" title="Заменить пароль">
    <el-form label-position="top">
      <el-form-item label="Введите пароль">
        <el-input type="password" show-password v-model="form.password">
        </el-input>
      </el-form-item>
      <el-form-item label="Повторите пароль">
        <el-input v-model="form.confirmPassword" show-password type="password">
        </el-input>
      </el-form-item>
    </el-form>
    <div class="mt-3 text-right">
      <el-button
        :disabled="
          form.password &&
          form.confirmPassword &&
          form.password !== form.confirmPassword
        "
        @click="updatePassword"
        type="primary"
        >Поменять пароль</el-button
      >
    </div>
  </el-dialog>
  <router-view @refetch-users="getUsers"></router-view>
</template>

<script>
import { changeUserPassword, getUsers } from "@/api/users";
export default {
  data() {
    return {
      users: [],
      form: {
        id: null,
        password: null,
        confirmPassword: null,
      },
      filters: {
        type: null,
        typeAccount: null,
      },
      search: "",
      debounce: null,
      showingInputs: {
        password: false,
        confirmPassword: false,
      },
      passwordChangeShow: false,
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    user() {
      return this.users.find((u) => u._id === this.userId);
    },
  },
  async mounted() {
    await this.getUsers();
  },
  methods: {
    async getUsers() {
      let filters = this.getFilters();
      this.users = await getUsers(filters);
    },
    searchUsers() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.users = [];
        this.getUsers();
      }, 500);
    },
    getFilters() {
      let params = {};
      if (this.filters.typeAccount) {
        params.role = this.filters.typeAccount;
      }
      if (this.filters.type) {
        params.status = this.filters.type;
      }
      if (this.search) {
        params.search = this.search;
      }
      return params;
    },
    viewUser(row) {
      this.$router.push({ name: "user-view", params: { userId: row._id } });
    },
    createUser() {
      this.$router.push({ name: "user-view", params: { userId: "new" } });
    },
    tableRowClassName({ row }) {
      if (row.status === -1) {
        return "warning-row";
      }
      return "";
    },
    changePasswordUser(id) {
      console.log(id);
      this.passwordChangeShow = true;
      this.form.id = id;
    },
    async updatePassword() {
      let data = await changeUserPassword(this.form.id, this.form.password);
      if (data._success) {
        this.passwordChangeShow = false;
        this.form.id = null;
        this.form.password = null;
      }
    },
  },
};
</script>

<style lang="scss">
.el-table .warning-row {
  background: oldlace;
}
.el-form:deep(.el-form-item__label) {
  line-height: 20px !important;
}
</style>
