<template>
  <ModalWindow title="Создание пользователя" ref="user">
    <template v-slot>
      <el-form class="p-5" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Имя">
              <el-input v-model="user.first_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Фамилия">
              <el-input v-model="user.last_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Email">
              <el-input
                :disabled="userId !== 'new'"
                v-model="user.email"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="userId === 'new'" :span="24">
            <el-form-item label="Пароль">
              <el-input
                v-model="user.password"
                type="password"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="24">
            <el-form-item label="Работы с персональными данными">
              <el-checkbox v-model="user.pd"></el-checkbox>
            </el-form-item>
          </el-col> -->

          <el-col :span="24">
            <el-form-item label="Роль">
              <el-select
                class="w-full"
                v-model="user.roles"
                multiple
                :multiple-limit="1"
              >
                <el-option
                  v-for="role in roles"
                  :key="role"
                  :label="role"
                  :value="role"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Доступ к batch">
              <el-switch class="w-full" v-model="user.batch_access">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template v-slot:footer>
      <div class="p-3 flex justify-end">
        <el-button
          v-if="userId !== 'new' && user.status === 1"
          @click="removeUser"
          :disabled="loading"
          plain
          type="danger"
        >
          Заблокировать
        </el-button>
        <el-button
          v-if="userId !== 'new' && user.status !== 1"
          @click="restoreUser"
          :disabled="loading"
          plain
          type="success"
        >
          Разблокировать
        </el-button>
        <el-button @click="save" :disabled="loading" plain type="primary">
          Сохранить
        </el-button>
      </div>
    </template>
  </ModalWindow>
</template>

<script>
import ModalWindow from "@/components/ModalWindow";

import {
  getUser as _gertUser,
  getRoles as _getRoles,
  createUser as _createUser,
  updateUser as _updateUser,
} from "@/api/users";

export default {
  components: {
    ModalWindow,
  },
  emits: ["refetch-users", "update:email"],
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      roles: [],
      loading: false,
      user: {
        email: null,
        password: null,
        roles: ["annotator"],
        first_name: null,
        last_name: null,
        pd: false,
        batch_access: false,
      },
    };
  },
  computed: {
    pageTitle() {
      return this.userId === "new"
        ? "Создание пользователя"
        : "Редактирование пользователя";
    },
  },
  async mounted() {
    await this.getRoles();
    if (this.userId !== "new") {
      await this.getUser();
    }
    await this.openModalWindow();
  },
  methods: {
    async openModalWindow() {
      const res = await this.$refs["user"].open();
      this.$router.push({ name: "users-list" });
      console.log(res);
    },
    async getUser() {
      this.loading = true;
      const user = await _gertUser(this.userId);
      this.loading = false;
      this.user = { ...this.user, ...user };
    },
    async getRoles() {
      this.roles = await _getRoles();
    },
    async createUser() {
      this.loading = true;
      const result = await _createUser(this.user);
      this.loading = false;
      this.postProcessing(result);
    },
    async updateUser() {
      this.loading = true;
      const result = await _updateUser(this.userId, this.user);
      this.loading = false;
      this.postProcessing(result);
    },
    async removeUser() {
      this.loading = true;
      const result = await _updateUser(this.userId, {
        ...this.user,
        status: -1,
      });
      this.loading = false;
      this.postProcessing(result);
    },
    async restoreUser() {
      this.loading = true;
      const result = await _updateUser(this.userId, {
        ...this.user,
        status: 1,
      });
      this.loading = false;
      this.postProcessing(result);
    },
    async save() {
      if (this.userId === "new") {
        await this.createUser();
      } else {
        await this.updateUser();
      }
    },
    postProcessing(result) {
      if (!result._success) {
        return;
      }
      this.$emit("refetch-users");
      this.$router.push({ name: "users-list" });
    },
    changeUser(val) {
      this.$emit("update:email", val);
      console.log(val);
    },
  },
};
</script>

<style></style>
