<template>
  <el-drawer
    class="p-0 m-0"
    :with-header="true"
    v-model="isOpen"
    :before-close="close"
  >
    <div class="h-full flex flex-col">
      <header class="">
        <slot name="header"></slot>
      </header>
      <main class="overflow-y-auto"><slot></slot></main>
      <footer class="mt-auto">
        <slot name="footer"></slot>
      </footer>
    </div>
  </el-drawer>
</template>

<script>
export default {
  popupController: null,
  data() {
    return {
      isOpen: true,
    };
  },
  beforeUnmount() {
    this.$options.popupController = null;
  },
  methods: {
    async open() {
      let resolve;
      let reject;
      const popupPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });
      this.$options.popupController = { resolve, reject };
      this.isOpen = true;
      return popupPromise;
    },
    close() {
      this.$options.popupController.resolve({ action: "cancel" });
      this.isOpen = false;
    },
  },
};
</script>

<style></style>
